<template>
  <div>
    <p>Generate a one time use pin-code and send it by email or sms:</p>
    <div class="mb-5 d-flex justify-space-around">
      <v-btn @click="trySendPinCode('email')" small depressed color="secondary" :disabled="timer">Send to Email</v-btn>
      <v-btn @click="trySendPinCode('phone')" small depressed color="secondary" :disabled="true">Send to Phone</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "PinCodeButtons",
  props: {
    purpose: String,
  },
  data() {
    return {
      timer: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sendPinCode"]),

    trySendPinCode(type) {
      this.sendPinCode({ type, purpose: this.purpose})
      this.timer = true;
      setInterval(() => {
        this.timer = false;
      }, 10000)
    },
  },
};
</script>
