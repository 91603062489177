<template>
  <full-view :title="$t('app.route.changeEmail')">
    <template v-slot:toolbar>
      <v-btn large depressed color="primary" @click="tryResetEmail">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid">
      <v-card class="ma-5 pa-5" :style="getViewHeightStyle(76)">
        <pin-code-buttons :purpose="getPurpose" />
        <v-text-field
          v-model="model.resetCode"
          :label="$t('user.pinCode')"
          :rules="rules.pinCode"
          clearable
          outlined
          append-icon="mdi-shield-check"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="model.email"
          :label="$t('user.newEmail')"
          :rules="rules.email"
          clearable
          outlined
          append-icon="mdi-lock"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="model.confirmEmail"
          :rules="rules.confirmEmail"
          :label="$t('user.confirmEmail')"
          append-icon="mdi-lock-check"
          clearable
          outlined
          validate-on-blur
        ></v-text-field>

        <v-divider />

        <v-card-actions>
          <v-btn large depressed color="primary" @click="tryResetEmail">
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </full-view>
</template>

<script>
import { mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import PinCodeButtons from "@/common/components/PinCodeButtons";
import { CODE_CONFIRM_EMAIL } from "@/store/actions/type.auth";

export default {
  name: "ChangeEmail",
  components: { FullView, PinCodeButtons },
  data() {
    return {
      valid: false,
      showEmail: false,
      model: { email: "", confirmEmail: "", resetCode: "" },
      rules: {
        pinCode: [
          (v) => !!v || "Pin-code is required, click on Pin-Code button above and find the code in your email or sms messages",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return re.test(String(v).toLowerCase()) || "Invalid email input";
          },
        ],
        confirmEmail: [
          (v) => !!v || "Confirm Email is required",
          (v) => v === this.model.email || "Email and confirmation must match",
        ],
      },
    };
  },
  computed: {
    getPurpose() {
      return CODE_CONFIRM_EMAIL;
    }
  },
  methods: {
    ...mapActions("auth", ["resetEmail"]),

    tryResetEmail() {
      this.$refs.form.validate();

      setTimeout(() => {
        if(this.valid) {
          this.resetEmail(this.model).then(() =>
            this.$$router.push({
              name: "settings",
              params: { back: { name: this.$route.name } }
            })
          );
        }
      }, 500);
    },
  },
};
</script>
